import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { OutboundLink } from 'gatsby-plugin-gtag';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "New Router",
  "path": "/Frequently_Asked_Question/New_Router/",
  "dateChanged": "2018-03-12",
  "author": "Mike Polinowski",
  "excerpt": "Have a new router. How do I connect the camera with the new router?",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "HD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='FAQs - New Router' dateChanged='2018-03-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Have a new router. How do I connect the camera with the new router?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/New_Router/' locationFR='/fr/Frequently_Asked_Question/New_Router/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "new-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#new-router",
        "aria-label": "new router permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New Router`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I Have a new router. How do I connect the camera with the new router?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: It's best just to follow the steps for the initial installation as seen on `}<OutboundLink href="https://install.instar.com" mdxType="OutboundLink">{`install.instar.de`}</OutboundLink>{`. If you have used the camera via WLAN, it is best to connect the camera via LAN cable once to the new router so that you can access the camera. You can then adjust the WLAN data in the camera to that of the new router.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Config/"
          }}>{`WWhere do you find the DHCP setting for a Full HD (1080p) Camera?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Web_User_Interface/720p_Series/Network/IP_Config/"
          }}>{`Where do you find the DHCP setting for an HD (720p) Camera?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
          }}>{`Where do you find the IP Address of my Camera?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Web_User_Interface/1080p_Series/Network/WiFi/"
          }}>{`Where can I find the WLAN settings in the 1080p WebUI?`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Web_User_Interface/720p_Series/Network/WiFi/"
          }}>{`Where can I find the WLAN settings in the 720p WebUI?`}</a></p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`If you no longer have the old router, you can transfer the WLAN data - the name of your old WLAN network and the password - to the new router. The camera will immediately recognise the old network name and log on with the old password.`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`If you no longer know the old WLAN password, you can either `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Direct_LAN_Connection/"
        }}>{`connect the camera directly to the PC or MAC via LAN cable`}</a>{` and activate the DHCP mode on the camera as described above. Or store the new WLAN access data directly on the camera.`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`The last option is the factory reset and then the steps for the initial installation as described on `}<a href="https://install.instar.com" target="_blank" rel="noopener noreferrer">{`install.instar.de`}</a>{`. If you have been using the camera via WLAN, it is best to first connect the camera to the new router via LAN cable so that you can access the camera. You can then adjust the WLAN data in the camera to that of the new router.`}</li>
    </ul>
    <h3 {...{
      "id": "indoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#indoor-cameras",
        "aria-label": "indoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Indoor Cameras`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Indoor_Cameras/IN-6001_HD/Camera_Reset/"
          }}>{`Reset: IN-6001 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Indoor_Cameras/IN-6012_HD/Camera_Reset/"
          }}>{`Reset: IN-6012 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Indoor_Cameras/IN-6014_HD/Camera_Reset/"
          }}>{`Reset: IN-6014 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Indoor_Cameras/IN-8003_HD/Camera_Reset/"
          }}>{`Reset: IN-8003 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Indoor_Cameras/IN-8015_HD/Camera_Reset/"
          }}>{`Reset: IN-8015 HD`}</a></p>
      </li>
    </ul>
    <h3 {...{
      "id": "outdoor-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#outdoor-cameras",
        "aria-label": "outdoor cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outdoor Cameras`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Outdoor_Cameras/IN-5905_HD/Camera_Reset/"
          }}>{`Reset: IN-5905 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Outdoor_Cameras/IN-5907_HD/Camera_Reset/"
          }}>{`Reset: IN-5907 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Outdoor_Cameras/IN-7011_HD/Camera_Reset/"
          }}>{`Reset: IN-7011 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Outdoor_Cameras/IN-9008_HD/Camera_Reset/"
          }}>{`Reset: IN-9008 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Outdoor_Cameras/IN-9010_HD/Camera_Reset/"
          }}>{`Reset: IN-9010 HD`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/en/Outdoor_Cameras/IN-9020_HD/Camera_Reset/"
          }}>{`Reset: IN-9020 HD`}</a></p>
      </li>
    </ul>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      